<template>
  <div class="ps-5 pe-5">
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="">
            <h5 class="dashboard-header">FAVOURITES</h5>
          </div>
          <div class="h-auto">

            <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="property-tab" data-bs-toggle="tab" data-bs-target="#property" type="button" role="tab" aria-controls="property" aria-selected="true">Properties</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="service-tab" data-bs-toggle="tab" data-bs-target="#service" type="button" role="tab" aria-controls="service" aria-selected="false">Services</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="property" role="tabpanel" aria-labelledby="property-tab">
                <div class="row">
                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                    <FavouriteList :userProperties="propertyFavourites"/>
                  </div>
                  <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 p-0 m-0">
                    <!-- AdService Section -->
                    <div class="position-relative h-100">
                      <div id="adPropertyContainer" class="ad-property-container no-scroll"> <!-- modal-tab -->
                        <!--                    &lt;!&ndash; Loading &ndash;&gt;-->
                        <!--                    <div class="fw-bold pt-5 pb-5 mt-2 mb-2" v-if="loadingAdDetails">Please choose property advertise</div>-->
                        <!--                    <Loader :loading="loadingAdDetails"></Loader>-->
                        <AdDetails v-if="!loadingAdDetails" :id="propertyId"></AdDetails>
                      </div>
                    </div>
                    <!-- End AdService Section -->
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="service" role="tabpanel" aria-labelledby="service-tab">
                <div class="row">
                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                    <FavouriteList :userServices="serviceFavourites"/>
                  </div>
                  <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 p-0 m-0">
                    <!-- AdService Section -->
                    <div class="position-relative h-100">
                      <div id="adServiceContainer" class="ad-service-container no-scroll"> <!-- modal-tab -->
                        <!--                    &lt;!&ndash; Loading &ndash;&gt;-->
                        <!--                    <div class="fw-bold pt-5 pb-5 mt-2 mb-2" v-if="loadingAdDetails">Please choose property advertise</div>-->
                        <!--                    <Loader :loading="loadingAdDetails"></Loader>-->
                        <AdService v-if="!loadingAdService" :id="serviceId"></AdService>
                      </div>
                    </div>
                    <!-- End AdService Section -->
                  </div>
                </div>
              </div>
            </div>


<!--            <ul class="nav nav-tabs">-->
<!--              <li class="active"><a data-toggle="tab" href="#properties">Properties</a></li>-->
<!--              <li><a data-toggle="tab" href="#services">Services</a></li>-->
<!--            </ul>-->

<!--            <div class="tab-content">-->
<!--              <div id="properties" class="tab-pane fade in active">-->
<!--                <div class="row">-->
<!--                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">-->
<!--                    <PropertyList :userProperties="propertyFavourites"/>-->
<!--                  </div>-->
<!--                  <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 p-0 m-0">-->
<!--                    &lt;!&ndash; AdService Section &ndash;&gt;-->
<!--                    <div class="position-relative h-100">-->
<!--                      <div id="adPropertyContainer" class="ad-property-container no-scroll"> &lt;!&ndash; modal-tab &ndash;&gt;-->
<!--                        &lt;!&ndash;                    &lt;!&ndash; Loading &ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <div class="fw-bold pt-5 pb-5 mt-2 mb-2" v-if="loadingAdDetails">Please choose property advertise</div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <Loader :loading="loadingAdDetails"></Loader>&ndash;&gt;-->
<!--                        <AdDetails v-if="!loadingAdDetails" :id="propertyId"></AdDetails>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; End AdService Section &ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div id="services" class="tab-pane fade">-->
<!--                <div class="row">-->
<!--                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">-->
<!--                    <ServiceList :userServices="serviceFavourites"/>-->
<!--                  </div>-->
<!--                  <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 p-0 m-0">-->
<!--                    &lt;!&ndash; AdService Section &ndash;&gt;-->
<!--                    <div class="position-relative h-100">-->
<!--                      <div id="adServiceContainer" class="ad-service-container no-scroll"> &lt;!&ndash; modal-tab &ndash;&gt;-->
<!--                        &lt;!&ndash;                    &lt;!&ndash; Loading &ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <div class="fw-bold pt-5 pb-5 mt-2 mb-2" v-if="loadingAdDetails">Please choose property advertise</div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <Loader :loading="loadingAdDetails"></Loader>&ndash;&gt;-->
<!--                        <AdService v-if="!loadingAdService" :id="serviceId"></AdService>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; End AdService Section &ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdService from "../AdService";
import Loader from "../Loader";
import FavouriteList from "./FavouriteList";
import AdDetails from "../AdDetails";

export default {
  name:"DashboardFavourites",
  components: {
    "FavouriteList": FavouriteList,
    "AdDetails": AdDetails,
    "AdService": AdService,
    "Loader": Loader
  },
  data(){
    return {
      user:this.$store.state.auth.user,
      // Properties
      propertyFavourites: null,
      propertyId: null,
      loadingAdDetails: true,
      // Services
      serviceFavourites: null,
      serviceId: null,
      loadingAdService: true
    }
  },
  created() {
    this.$root.$on('refreshUserProperties', (filters = null, id, paginate) => this.getUserProperties(filters, id, paginate));
    this.$root.$on('setPropertyIdDashboard', (id) => this.setPropertyIdDashboard(id));
    this.$root.$on('refreshUserServices', (filters = null, id, paginate) => this.getUserServices(filters, id, paginate));
    this.$root.$on('setServiceIdDashboard', (id) => this.setServiceIdDashboard(id));
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('refreshUserProperties');
    this.$root.$off('setPropertyIdDashboard');
    this.$root.$off('refreshUserServices');
    this.$root.$off('setServiceIdDashboard');
  },
  async mounted() {
    if (this.$store.state.auth.user && this.$store.state.auth.user.id) {
      await this.getUserFavouriteProperties('Property');
    }
    if (this.$store.state.auth.user && this.$store.state.auth.user.id) {
      await this.getUserFavouriteServices('Service');
    }
  },
  methods: {
    getUserFavouriteProperties(type = null) {
      return axios.post('api/favourites', {type: type }).then(({data})=> {
        this.propertyFavourites = data;
        this.propertyId = null;
      }).catch(({response})=>{
        if (response && response.data) {
          alert(response.data.message)
        }
      }).finally(()=> {
        this.processing = false
      })
    },
    setPropertyIdDashboard(id) {
      if (id) {
        this.propertyId = id;
      }
    },
    getUserFavouriteServices(type = null) {
      return axios.post('api/favourites', {type: type }).then(({data})=> {
        this.serviceFavourites = data;
        this.serviceId = null;
      }).catch(({response})=>{
        if (response && response.data) {
          alert(response.data.message)
        }
      }).finally(()=> {
        this.processing = false
      })
    },
    setServiceIdDashboard(id) {
      if (id) {
        this.serviceId = id;
      }
    }
  },
  watch: {
    "propertyId": {
      handler: function (val) {
        this.loadingAdDetails = false;
      }, deep: true, immediate: true
    },
    "serviceId": {
      handler: function (val) {
        this.loadingAdService = false;
      }, deep: true, immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

.dashboard-header {
  letter-spacing: 2px;
}

.ad-property-container {
  border: 1px solid rgba(0,0,0,.125);
  margin: 5px 0 5px 0;
  height: 82vh;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  z-index: 11;
  top: 10.5vh;
  left: 32vw;
  //background-color: rgba(33, 150, 243, 50%);
  //background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 10px;
  background-clip: border-box;
}

.ad-property-container a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.ad-property-container a:hover {
  color: #818080;
}

.ad-service-container {
  border: 1px solid rgba(0,0,0,.125);
  margin: 5px 0 5px 0;
  height: 82vh;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 10.5vh;
  left: 32vw;
  //background-color: rgba(33, 150, 243, 50%);
  //background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 10px;
  background-clip: border-box;
}

.ad-service-container a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.ad-service-container a:hover {
  color: #818080;
}

</style>