<template>
  <div class="container-list-services ms-2 me-2 ps-2 pe-2">

    <div class="row">
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 dash-services position-relative h-100">
        <!-- No Results -->
        <div class="fw-bold p-5 m-2" v-if="!pagination && loading">No results to show</div>
        <!-- Loading -->
        <Loader class="mt-5 mb-5 pt-5 pb-5" :loading="loading" :small="false"></Loader>
        <!-- Loop Services -->
        <div v-if="!loading">
          <div v-for="(service, index) in services.data"
               :key="index"
          >
            <div :class="serviceId === service.id ? 'service-details p-2 active-service-details' : 'service-details p-2'"
                 :id="'service-'+service.id"
                 v-on:click="openServiceDetailsModal(service.id, index)"
            >
              <div class="row ps-3 m-0 text-start service-icons">
                <div class="col-1">
                  <ul class="list-unstyled">
                    <!-- Price -->
                    <li>
                      <span data-tooltip="tooltip">
                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                        <span class="tooltiptext">Price</span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="col-7">
                  <ul class="list-unstyled w-100">
                    <!-- Price -->
                    <li class="list-relative">
                      <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="price-color"> {{ convertNumberToCommas(service.price) }} </span>
                        <span v-if="service && service.service_payment_type" class="fw-bold"> per {{ service.service_payment_type.type }} </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-4">
                  <!-- Start Action Buttons -->
                  <div class="action-dropdown">
                    <button @click.stop="preventParentClick()" class="btn-action-dropdown" type="button" :id="'actionDropdown'+service.id" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                      <font-awesome-icon icon="ellipsis-h" style="color: #868e96;" />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end list-unstyled action-buttons" :aria-labelledby="'actionDropdown'+service.id">
                      <li class="action-item">
                        <router-link :to="{ name: 'addeditservice', params: { id: service.id }}">
                          <button :class="processing ? 'disabled action-button-edit' : 'action-button-edit'">
                            <font-awesome-icon icon="pencil-alt" class="min-width-30 icon-edit" />
                            EDIT
                          </button>
                        </router-link>
                      </li>
                      <li class="action-item">
                        <button :class="processing ? 'disabled action-button-publish' : 'action-button-publish'"
                                @click.stop="publishService(service.id, service.is_published)"
                        >
                          <font-awesome-icon icon="paper-plane" class="min-width-30 icon-publish" />
                          {{ service.is_published ? "UNPUBLISH" : "PUBLISH" }}
                        </button>
                      </li>
                      <li class="action-item">
                        <button :class="processing ? 'disabled action-button-complete' : 'action-button-complete'"
                                @click.stop="completeService(service.id, service.is_completed)"
                        >
                          <font-awesome-icon icon="check" class="min-width-30 icon-complete" />
                          {{ service.is_completed ? "UNCOMPLETE" : "COMPLETE" }}
                        </button>
                      </li>
                      <li class="action-item">
                        <button :class="processing ? 'disabled action-button-delete' : 'action-button-delete'"
                                @click.stop="deleteService(service.id)">
                          <font-awesome-icon icon="trash" class="min-width-30 icon-delete" />
                          DELETE
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="service-info-list-relative pt-1 pb-1">
                <div v-if="service.service_title" class="text-start ps-3 service-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <span class="fw-bold">Title:</span>
                  {{ service.service_title }}
                </div>
              </div>
              <div class="service-info-list-relative pt-1 pb-1">
                <div v-if="service.address" class="text-start ps-3 service-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <span class="fw-bold">Area Covered:</span>
                  {{
                    (service.address.county ? service.address.county : '') +
                    (service.address.city ? ', ' + service.address.city : '') +
                    (service.address.line_1 ? ', ' + service.address.line_1 : '') +
                    (service.address.postcode ? ', ' + service.address.postcode : '')
                  }}
                </div>
              </div>
              <div class="row service-info m-0 pt-1 pb-1 ps-3 text-start">
                <div class="col-6 m-0 p-0 text-start">
                  <span class="fw-bold">Posted:</span>
                  {{ calculateCreatedDate(service.created_at) }}
                </div>
                <div class="col-6 m-0 p-0 text-center position-relative">
                  <div class="text-center service-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                    <span class="fw-bold">{{ service.occupation ? service.occupation.type.toUpperCase() : '' }}</span>
                  </div>
                </div>
              </div>
              <div class="row service-thumbnails">
                <div class="col-8">
                  <div :id="'carouselCaptions-'+index" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">
                      <!--                    <div v-if="service.is_cash" class="cash-caption d-none d-md-block">-->
                      <!--                      <p>Cash Only</p>-->
                      <!--                    </div>-->
                      <!--                    <div v-if="service.is_auction" class="auction-caption d-none d-md-block">-->
                      <!--                      <p>Auction</p>-->
                      <!--                    </div>-->
<!--                      <div v-if="property.is_coming_soon" class="coming-soon-caption d-none d-md-block">-->
<!--                        <p>Coming Soon</p>-->
<!--                      </div>-->
                      <div v-if="service.documents_service_images.length === 0">
                        <img :src="require(`@/assets/no-image-found.png`)"
                             class="d-block w-100 img-fluid thumbnails-height"
                             alt="..."
                        >
                      </div>
                      <div v-for="(doc, num) in service.documents_service_images" :key="num" :class="num === 0 ? 'active carousel-item' : 'carousel-item'">
                        <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg"
                             class="d-block w-100 img-fluid thumbnails-img"
                             alt="..."
                        >
<!--                        <img v-if="getImage(doc.file_name)" :src="require(`@/assets/${doc.file_name}`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
<!--                        <img v-else :src="require(`@/assets/no-image-found.png`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
                      </div>
                    </div>

                    <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCaptions-'+index" data-bs-slide="prev" v-on:click.stop="preventParentClick()">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCaptions-'+index" data-bs-slide="next" v-on:click.stop="preventParentClick()">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>

                  </div>
                </div>
                <div class="col-4 p-0 m-0 service-details-section">
                  <!--                  <div class="row p-0 m-0 text-start service-icons">-->
                  <!--                    <div class="col-3">-->
                  <!--                      <ul class="list-unstyled">-->
                  <!--                        &lt;!&ndash; Price &ndash;&gt;-->
                  <!--                        <li>-->
                  <!--                      <span data-tooltip="tooltip">-->
                  <!--                        <font-awesome-icon icon="pound-sign" class="icon-fa" />-->
                  <!--                        <span class="tooltiptext">Price</span>-->
                  <!--                      </span>-->
                  <!--                        </li>-->
                  <!--                      </ul>-->
                  <!--                    </div>-->
                  <!--                    <div class="col-8">-->
                  <!--                      <ul class="list-unstyled w-100">-->
                  <!--                        &lt;!&ndash; Price &ndash;&gt;-->
                  <!--                        <li class="list-relative">-->
                  <!--                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
                  <!--                            <span class="price-color"> {{ convertNumberToCommas(service.price) }} </span>-->
                  <!--                            <span v-if="service && service.service_payment_type" class="fw-bold"> per {{ service.service_payment_type.type }} </span>-->
                  <!--                          </div>-->
                  <!--                        </li>-->
                  <!--                      </ul>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                </div>
              </div>

            </div>
            <!--            &lt;!&ndash; Start AdSense &ndash;&gt;-->
            <!--            <div v-if="(index !== 0 && index % 4 === 0) || checkIfLastIndex(index)">-->
            <!--              <AdSense></AdSense>-->
            <!--            </div>-->
            <!--            &lt;!&ndash; End AdSense &ndash;&gt;-->

          </div>
        </div>
        <!-- End Loop Services -->

        <!-- Pagination -->
        <ul class="pagination justify-content-center" v-if="pagination && !loading">
          <li class="page-item" :class="{disabled: metaData.current_page === 1}">
            <a class="page-link" @click="previous"><span aria-hidden="true">&laquo;</span></a>
          </li>
          <li
              v-for="number in paginationData"
              class="page-item"
              :class="{active: metaData.current_page === number}"
          >
            <a class="page-link" @click="navigateTo(null, number)">{{ number }}</a>
          </li>
          <li class="page-item" :class="{disabled: metaData.current_page === metaData.last_page}">
            <a class="page-link" @click="next"><span aria-hidden="true">&raquo;</span></a>
          </li>
        </ul>

      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios"
import {mapActions} from "vuex";
import AdService from '../AdService'
import Loader from "../Loader.vue"
import AdSense from "../AdSense"
import AdDetails from "../AdDetails";
import AddBookmark from "../AddBookmark";

export default {
  name: 'FavouriteList',
  components: {
    'AdService': AdService,
    'AdDetails': AdDetails,
    'AddBookmark': AddBookmark,
    'Loader': Loader,
    'AdSense': AdSense
  },
  props: ['userProperties', 'userServices'],
  data(){
    return {
      defaultImg: require('@/assets/no-image-found.png'),
      // Services
      serviceId: null,
      services: this.userServices,
      // Properties
      activePropertyDetailsModal: false,
      activeDashPropDetailsThumbnail: undefined,
      properties: this.userProperties,
      propDetailsId: null,
      // Other
      pagination: false,
      metaData: null,
      linksData: null,
      paginationData: null,
      loading: true,
      processing: true,
      validationErrors: null
    }
  },
  async mounted() {
    if (this.services) {
      this.loading = false;
    } else {
      this.loading = true;
      this.pagination = false;
    }
    if (this.properties) {
      this.loading = false;
    } else {
      this.loading = true;
      this.pagination = false;
    }
    this.processing = false;
  },
  methods: {
    navigateTo(url, page = null) {
      if (page) {
        url = this.linksData.first.substring(0, this.linksData.first.lastIndexOf('=') + 1) + page;
      }
      axios.post(url).then(({data})=>{
        // I should really trigger setProperties from vuex
        this.services = data;
        this.calculatePagination();
      }).catch(({response})=>{
        alert(response.data.message)
      }).finally(()=> {
        this.processing = false
      })
    },
    previous() {
      if (this.linksData.prev) {
        this.navigateTo(this.linksData.prev)
      }
    },
    next() {
      if (this.linksData.next) {
        this.navigateTo(this.linksData.next)
      }
    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    async completeService(id, completed) {
      let isCompleted = completed ? 'open (uncomplete)' : 'close (complete)';
      if (!confirm("Are you sure you want to " + isCompleted + " this advertise?")) {
        return;
      }
      this.processing = true;

      await axios.post("api/services/" + id + "/complete").then(response => {
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserServices', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    async deleteService(id) {
      if (!confirm("Are you sure you want to remove this service form your portfolio? ID: " + id)) {
        return;
      }
      this.processing = true;

      await axios.post("api/services/" + id + "/delete").then(response => {
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserServices', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    async publishService(id, published) {
      let isPublished = published ? 'unpublish' : 'publish';
      if (!confirm("Are you sure you want to " + isPublished + " this advertise?")) {
        return;
      }
      this.processing = true;

      await axios.post("api/services/" + id + "/publish").then(response => {
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserServices', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    openServiceDetailsModal(id, index) {
      this.setActiveDashServiceDetailsThumbnail(id);
      this.$root.$emit('setServiceIdDashboard', id);
    },
    setActiveDashServiceDetailsThumbnail(id) {
      this.serviceId = id;
    },
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActiveDashServiceDetailsThumbnail)
    },
    checkIfLastIndex(index){
      return index === this.services.data.length - 1;
    },
    calculateCreatedDate(date) {
      let resultDate = null;

      let currentDate = new Date();
      let propDate = new Date(date);
      let numberOfDays = (currentDate.getTime() - propDate.getTime()) / (1000*60*60*24);

      switch (true) {
        case numberOfDays <= 1:
          resultDate = "Today";
          break;
        case (numberOfDays <= 30 && numberOfDays > 1):
          if(numberOfDays < 2) {
            resultDate = Math.round(numberOfDays)+" day ago";
          } else {
            resultDate = Math.round(numberOfDays)+" days ago";
          }
          break;
        case numberOfDays > 30:
          let numberOfMonths = Math.round(numberOfDays / 30);
          if(numberOfMonths === 1) {
            resultDate = numberOfMonths+" month ago";
          } else {
            resultDate = numberOfMonths+" months ago"
          }
          break;
      }

      return resultDate;

    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    calculatePagination() {
      if(this.metaData && this.linksData) {

        // Current page any but total pages less than 10
        if (this.metaData.last_page < 10) {
          this.paginationData = this.metaData.last_page
        }

        // Current page 2 or less and last_page pages more than 10
        if (this.metaData.current_page <= 2 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 3 and last_page pages more than 10
        if (this.metaData.current_page === 3 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 4 and last_page pages more than 10
        if (this.metaData.current_page === 4 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, 5, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 5 and last_page pages more than 10
        if (this.metaData.current_page === 5 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 6 or more and last_page pages more than 10
        if (this.metaData.current_page >=6 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page almost or equal last_page which is more than 10
        if (this.metaData.last_page >= 10 &&
            (this.metaData.current_page === this.metaData.last_page ||
                this.metaData.current_page === this.metaData.last_page - 1 ||
                this.metaData.current_page === this.metaData.last_page - 2
            )
        ) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 3, this.metaData.last_page - 2, this.metaData.last_page - 1, this.metaData.last_page]
        }
      }
    }
  },
  created: function () {

  },
  watch: {
    'userServices': {
      handler: function () {
        this.services = this.userServices;
        this.metaData = this.userServices.meta;
        this.linksData = this.userServices.links;
      }, deep: true
    },
    'services': {
      handler: function () {
        this.metaData = this.services.meta;
        this.linksData = this.services.links;
        if (
            this.services &&
            this.services.data &&
            this.services.data.length > 0
        ) {
          this.loading = false;
          this.calculatePagination();
          this.pagination = true;
        } else {
          this.loading = true;
          this.pagination = false;
        }
      }, deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Start Text truncate - white space - for long texts and icons */
.service-info-list-relative {
  width: 100%;
  min-height: 30px;
  position: relative;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item {
  width: 100%;
  box-sizing: border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing: border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 0 0 1px;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

/* Services Search Filters */
.search-dash-service-filters {
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  height: 16vh;
  z-index: 1002;
  margin: 5px 0 5px 0;
  top: 10.5vh;
}
/* End Services Search Filters */

/* Start Action Buttons */
.dash-services .action-dropdown {
  z-index: 1001;
  min-height: 30px;
  text-align: end;
  background-color: rgba(255, 255, 255 , 0);
  color: #868e96;
  cursor: pointer;

  .btn-action-dropdown {
    border: none;
    border-radius: 50%;
    background-color: #e9ecef;
    cursor: pointer;
  }
  .btn-action-dropdown:hover {
    cursor: pointer;
  }
}

.action-dropdown:hover {
  cursor: pointer;
}

.action-buttons {
  z-index: 1001;
  .action-item {
    padding: 2px 5px 2px 5px;
    margin: 2px 5px 2px 5px;
    min-width: 80px;
    font-size: 13px;
  }

  .action-button-complete {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-complete:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-complete:hover .icon-complete {
    color: #42b983;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-publish {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-publish:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-publish:hover .icon-publish {
    color: deepskyblue;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-edit {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-edit:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-edit:hover .icon-edit {
    color: orange;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-delete {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-delete:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-delete:hover .icon-delete {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}
/* End Action Buttons */

.dash-services {
  .service-details {
    background-color: #f1f1f1;
    border-radius: 15px;
    transition: 0.5s;
    margin: 5px 0px 5px 0px;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  .service-details:hover {
    cursor: pointer;
  }
  .active-service-details {
    background-color: white;
    transition: 0.5s;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
}

.ad-service-container {
  border: 1px solid rgba(0,0,0,.125);
  margin: 5px 0 5px 0;
  height: 85vh;
  width: 65vw;
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 10.5vh;
  left: 32vw;
  //background-color: rgba(33, 150, 243, 50%);
  //background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 10px;
  background-clip: border-box;
}

.ad-service-container a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.ad-service-container a:hover {
  color: #818080;
}

.service-info {
  cursor: pointer;
  font-size: 13px;
}

.service-icons {
  --bs-gutter-x: 0;
  ul {
    margin: 0;
  }
}

.custom-col-length {
  .col-3 {
    width: 50% !important;
  }
}

/*
  START
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

.service-thumbnails {
  .thumbnails-img {
    width: 100%;
    cursor: pointer;
    height: auto;
    max-height: 200px;
    min-height: auto;
    //max-height: 200px !important;
    //min-height: 200px !important;
  }
  .carousel-inner{
    width:100%;
    border-radius: 20px;
    .auction-caption {
      background-color: white;
      text-align: left;
      min-width: 80px;
      max-width: 80px;
      min-height: 25px;
      max-height: 25px;
      border-radius: 5% 5% 100% 5%;
      padding: 5px 5px 5px 10px;
      font-size: 11px;
      position: absolute;
      top: -1px;
      bottom: auto;
      left: -1px;
      z-index: 10;
    }
    .cash-caption {
      background-color: white;
      text-align: right;
      min-width: 80px;
      max-width: 80px;
      min-height: 25px;
      max-height: 25px;
      border-radius: 5% 5% 5% 100%;
      padding: 5px 3px 5px 5px;
      font-size: 11px;
      position: absolute;
      top: -1px;
      bottom: auto;
      right: 0;
      z-index: 10;
    }
    .coming-soon-caption {
      background-color: gold;
      color: black;
      text-align: center;
      min-width: 100%;
      max-width: 100%;
      min-height: 25px;
      max-height: 25px;
      border-radius: 100% 100% 3% 3%;
      padding: 5px 5px 5px 10px;
      font-size: 11px;
      position: absolute;
      //top: -1px;
      bottom: 0;
      left: -1px;
      z-index: 10;
    }
  }
  .service-details-section {
    .col-8 {
      width: 72% !important;
    }
  }
  .service-details-section *:hover {
    cursor: pointer !important;
  }
}

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
}
/* end tooltip - popups info on icons */

.pagination {
  cursor: pointer;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 500px) {

}
@media screen and (min-width: 501px) {

}
@media screen and (min-width: 769px) {

}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media screen and (min-width: 1920px) {

}


</style>
